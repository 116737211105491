
import { STORE } from "../../store";
import { findQuestions } from "../Response";
import { IQuestion } from "../../interfaces/Question";
import { handleInAppropriateLangMethod, handleUnsureAiResponseMethod } from "../../services/ai";


/**
 * @author Mayank
 * @date 31/01/2024
 * @description : This function is used to create bot response as per user's configuration in case of invalid ai response
 * @param activeQuestion : Current active question for creating payload
 * @param result : It contains type and the message which we have to send
 * @returns 
 */

export const getFinalAiQuestion = async (activeQuestion: IQuestion, result: any) => {
	let finalAiQuestion;
	try {
		switch (result.type) {
			//if user has configured to show error message only then we are sending error message
			case "text": {
				finalAiQuestion = [
					{
						...activeQuestion,
						label: result.message,
						type: 'AI',
						delay: 0,
						shouldFeedbackVisible: true,
						_id: undefined,
						suggestions: activeQuestion.suggestions
					}]
				break;
			}
			// if user has configured for live_chat then we are sending a button component on click of which live chat will be triggered
			case "button": {
				finalAiQuestion = [
					{
						...activeQuestion,
						type: "button",
						label: result.message.label,
						options: result.message.options,
						delay: 0,
						shouldFeedbackVisible: true,
						_id: undefined,
					}];
				break;
			}
			//if user has configured to invoke new message then we are sending configured message
			case "question": {
				const { flows } = STORE.getState();
				const questions = flows[0]?.questions;
				const index = questions.findIndex((que: IQuestion) => que.id === result.message.next.target);
				finalAiQuestion = [{
					...activeQuestion,
					label: result.message.label,
					type: 'question',
					next: {
						type: 'question',
						target: result.message.next.target
					}
				}, ...await findQuestions(questions, index)];
				break;
			}
			case "end-chat": {
				finalAiQuestion = [
					{
						...activeQuestion,
						label: result.message,
						delay: 0,
						type: 'question',
						shouldFeedbackVisible: true,
						_id: undefined,
						next: {
							type: 'question',
							target: "end"
						}
					}]
				break;
			}
			default: {
				return finalAiQuestion;
			}
		}
		return finalAiQuestion;
	} catch {
		return [];
	}
}

/**
 * This function will check user's configuration for invalid ai response and process accordingly
 */
export const handleUnsureAiResponse = (aiResponse: any) => {
	const { ai } = STORE.getState();
	let aiConfiguredMessages = ai?.messages;

	return handleUnsureAiResponseMethod(aiResponse, aiConfiguredMessages);
};

/**
 * This function will check the issue with ai response and process accordingly
 */
export const handleInvalidAiResponse = (response: any) => {

	let result;
	try {
		switch (response.case) {
			case 'unsure': {
				result = handleUnsureAiResponse(response);
				break;
			}
			case 'inappropriate_lang': {
				result = handleInAppropriateLang();
				break;
			}
			default: {
				return result;
			}
		}

		return result;
	} catch {
		return result
	}
}

export const handleInAppropriateLang = () => {
	const { ai, flows } = STORE.getState();

	let aiConfiguredMessages = ai?.messages;

	return handleInAppropriateLangMethod(aiConfiguredMessages, flows);
};

import axios from "axios";
import { STORE } from "../../store";
import { IQuestion } from "../../interfaces/Question";
import { httpRequest } from "../../services/network";
import { encryptPayload } from "../../services/saveResponse";
import { addAttributes, updateFlow, updateRedirectFlows, updateSingleMessage } from "../../actions";
import { submitAnswer } from "../Answer";
import { Notes } from "../../components/Tickets/Detail";
import { IFlow } from "../../interfaces/Flow";


/**
 * FIXME: We can remodify these files just like other services so that we have a common nomenclature through botpenguin.
 * eg:
 * message.tsx for userinput message handling
 * send-message.tsx for finding questions and sending
 * save-message.tsx for save-response API and updateMeta API
 * 
 * Let's discuss on this
 */
const constants = ['STATEMENT', 'IMAGE', 'CONTACT', 'VIDEO'];

export const findQuestions = async (questions: any, index: number) => {
  try {
    const state = STORE.getState();
    let finalQuestions: any[] = [];
    let questionsAlreadyAnswered = state.messages
      .filter((message: any) => !!message.questionId && message.isValid)
      .map((message: any) => message.questionId);

    if (!questions[index]) {
      return finalQuestions;
    }

    if (constants.includes(questions[index].type.toUpperCase())) {
      finalQuestions = [...finalQuestions, questions[index]];
      if (questions[index].next.target) {
        if (questions[index].next.target.match(/end/)) {
          return finalQuestions;
        }
        const newIndex = questions.findIndex((ques: any) => ques.id === questions[index].next.target);
        if (newIndex === -1) {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, index + 1)];
        } else {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, newIndex)];
        }
      } else {
        finalQuestions = [...finalQuestions, ...await findQuestions(questions, index + 1)];
      }
    } else {
      if (
        questions[index].skipIfAnswered &&
        questionsAlreadyAnswered.includes(questions[index].id) &&
        ['EMAIL', 'PHONE', 'NAME'].includes(questions[index].type.toUpperCase())
      ) {
        const newIndex = questions.findIndex((ques: any) => ques.id === questions[index].next.target);
        if (newIndex === -1) {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, index + 1)];
        } else {
          finalQuestions = [...finalQuestions, ...await findQuestions(questions, newIndex)];
        }

      } else {
        finalQuestions = [...finalQuestions, questions[index]];
      }
    }

    /**
     * Author: Suraj Kumar
     * Dated: 10 October 2024
     * Description:
     * - We check if a redirect component is present in the flow.
     * - If a redirect component exists, we retrieve it to get the next question flow ID.
     * - Then, we filter out the redirect component from the finalQuestions array, as it will not be rendered on the UI.
     * - Finally, we obtain the flow ID based on the redirectFlowDetails. If redirectFlowDetails is available, we 
     * get the next target flow ID; otherwise, we retain the same flow ID.
     */
    const redirectComponent = finalQuestions.find(question => question.type === 'redirect');
    if (redirectComponent) {
      const flow = await handleRedirectFlow(redirectComponent);
      const redirectFlowQuestions = [...finalQuestions, ...await findQuestions(flow.questions, 0)];
      finalQuestions = redirectFlowQuestions.filter(question => question.type !== 'redirect');
    }

    const apiComp = finalQuestions.find(question => question.type === 'api');
    if (apiComp) {

      const nextQuestionId = await triggerApiComp(apiComp);

      const newIndex = questions.findIndex((ques: IQuestion) => ques.id === nextQuestionId);
      const question = finalQuestions.filter(question => question.type !== 'api');
      if (newIndex === -1) {
        finalQuestions = [...question, ...await findQuestions(questions, index + 1)];
      } else {
        finalQuestions = [...question, ...await findQuestions(questions, newIndex)];
      }
    };

    return finalQuestions;

  } catch (error) {
    return [];
  }
};

export const getMappedCustomAttributes = (response: any) => {
  return response?.response?.data?.customAttributes || response?.data?.customAttributes || response?.data?.data?.customAttributes;
}

/**
 * Date: 6/Dec/2023
 * Summary: This function replaces attribute placeholders with their respective values in an object.
 * Description:
 *
 * Recursively replaces attribute in a given object or array of objects.
 * The attribute keys are replaced based on the provided `inboxUser` and `subscriber` information.
 * The function first checks if the input question is null or undefined.
 * If true, it returns the input unchanged.
 * If the input is a string, the function replaces {{key}} placeholders with corresponding values from the attributes array.
 * It also performs additional specific replacements for known attribute keys :
 * ({{name}}, {{email}}, {{phone}}) based on information from inboxUser and subscriber.
 * If the input is an array, the function applies itself recursively to each element of the array.
 * If the input is an object, the function applies itself recursively to each property of the object.
 * If the input is neither a string, array, nor object, the function returns it unchanged.
 */
// TODO: Optimise It should only work for labels, options and such item
export const replaceAttributeWithValueInObject = (question: any, attributes: any): any => {

  if (!question) {
    return question;
  }

  if (typeof question === 'string') {

    let text: string = question;
    (attributes || []).forEach((item: any) => {
      const value = typeof item?.value === 'string'
        ? item.value
        : JSON.stringify(item.value);

      text = text
        .split(item.key)
        .join(value);
    });
    return text;
  } else if (Array.isArray(question)) {

    return question.map(item => replaceAttributeWithValueInObject(item, attributes));
  } else if (typeof question === 'object') {
    for (const key of Object.keys(question)) {
      question[key] = replaceAttributeWithValueInObject(question[key], attributes);
    }
    return question;
  }
  return question;
};

export const getAttributesList = (userId: string) =>
  new Promise((resolve) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}attributes/${userId}`,
      method: 'GET',
    })
      .then(res => resolve(res?.data?.data || []))
      .catch(err => resolve(err));
  });

export const setAttributeValue = (activeQuestion: IQuestion, answer: string, attributeList = []) => {

  if (['name', 'email', 'phone'].includes(activeQuestion?.type) && !!answer) {
    STORE.dispatch(addAttributes([{
      key: `{{${activeQuestion?.type}}}`,
      value: answer
    }]));

  }

  if (activeQuestion?._attribute && !!answer && !!activeQuestion?.type) {
    const attribute: any = attributeList.find((attribute: { _id: string; key: string }) => attribute?._id === activeQuestion?._attribute);
    if (attribute) {

      STORE.dispatch(addAttributes([{
        key: `{{${attribute.key}}}`,
        value: answer
      }]));
    }
  }
}

export const triggerApiComp = async (question: IQuestion) => {
  try {
    const { environment, attributes } = STORE.getState();
    const data = {
      _user: environment._user,
      requestId: question?.apiId || '',
      uuid: environment.uuid,
      payload: {
        _flow: environment._flow,
        _subscriber: environment._chatWindowUser,
        _user: environment._user,
        _bot: environment._id,
        ...(environment?.selectedTags && { tagIds: environment.selectedTags })
      },
      attributes: attributes.map(({ key, value }) => ({
        key: key.replace(/{{|}}/g, ''), // Remove curly braces from key
        value
      }))
    }
    const response: any = await triggerApiRequest(data);

    const status = response?.response?.status || response?.status;

    let customAttributes = getMappedCustomAttributes(response);

    customAttributes = Array.isArray(customAttributes) ? customAttributes : [];
    customAttributes = customAttributes.filter((attribute: any) => !!attribute.key);

    const nextQuestionId = question.options?.find(item => String(item.value) === String(status))?.next?.target
      || (question?.options && question?.options[0]?.next?.target);

    if (customAttributes) {

      STORE.dispatch(addAttributes(customAttributes));
    }

    return nextQuestionId;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export const triggerApiRequest = async (data: any) =>
  new Promise((resolve) => {
    axios({
      url: `${process.env.REACT_APP_API_URL}integrations/api-requests/trigger-api-request`,
      method: 'POST',
      data: { binary: encryptPayload(data) }
    })
      .then(resolve)
      .catch(resolve);
  });

export const saveMessageFeedback = async (payload: any) => new Promise(async (resolve, reject) => {
  resolve(true);
  const { environment, messages } = STORE.getState();

  await httpRequest('PUT', 'save-feedback', {
    binary: encryptPayload({
      payload: {
        ...payload
      },
      _bot: environment._id,
      _user: environment._user,
      uuid: environment.uuid
    })
  });

  const message = messages.find((message: any) => message.mid === payload.mid);
  STORE.dispatch(updateSingleMessage({ ...message, ...payload }));
});


export const skip = async () => {
  return await submitAnswer('Skip', 'Skip');
};

export const back = async () => {
  const { environment } = STORE.getState();
  return await submitAnswer('Back', 'Back', environment.lastQuestion);
};

export const getKnowledgeBaseContent = () =>
  new Promise((resolve) => {
    const { environment } = STORE.getState();

    const binary = encryptPayload({ botId: environment._id });

    axios({
      url: `${process.env.REACT_APP_API_URL}help-automation/get-gitbook-workspace-content`,
      method: 'POST',
      data: { binary }
    })
      .then(res => resolve(res?.data?.data || []))
      .catch(err => resolve(err));
  });

export const getOwnTickets = (userMeta: { name: string, email: string }) =>
  new Promise((resolve, reject) => {
    const { environment } = STORE.getState();

    const binary = encryptPayload({ botId: environment._id, email: userMeta.email });

    axios({
      url: `${process.env.REACT_APP_API_URL}help-automation/get-hubspot-tickets`,
      method: 'POST',
      data: { binary }
    })
      .then(res => resolve(res?.data?.data || []))
      .catch(err => reject(err));
  });

export const createHubspotTicket = (payload: any) =>
  new Promise((resolve, reject) => {
    const { environment } = STORE.getState();

    const binary = encryptPayload({ botId: environment._id, ...payload });

    axios({
      url: `${process.env.REACT_APP_API_URL}help-automation/create-hubspot-ticket`,
      method: 'POST',
      data: { binary }
    })
      .then(res => {

        if (!res || !res.data || !res.data.success) {
          throw new Error('Failed');
        }
        resolve(res?.data?.data || [])
      })
      .catch(err => reject(err));
  });

export const getCategories = () =>
  new Promise((resolve, reject) => {
    const { environment } = STORE.getState();

    const binary = encryptPayload({ botId: environment._id });

    axios({
      url: `${process.env.REACT_APP_API_URL}help-automation/get-categories`,
      method: 'POST',
      data: { binary }
    })
      .then(res => resolve(res?.data?.data || []))
      .catch(err => reject(err));
  });

export const getHubspotNotes = (ticketId: string) =>
  new Promise<{ results: Notes[] }>((resolve, reject) => {
    const { environment } = STORE.getState();

    const binary = encryptPayload({ botId: environment._id, ticketId });

    axios({
      url: `${process.env.REACT_APP_API_URL}help-automation/get-hubspot-ticket-notes`,
      method: 'POST',
      data: { binary }
    })
      .then(res => resolve((res?.data?.data || []) as any))
      .catch(err => reject(err));
  });

export const createHubspotNotes = (ticketId: string, noteContent: string) =>
  new Promise((resolve, reject) => {
    const { environment } = STORE.getState();

    const binary = encryptPayload({ botId: environment._id, ticketId, noteContent });

    axios({
      url: `${process.env.REACT_APP_API_URL}help-automation/create-note`,
      method: 'POST',
      data: { binary }
    })
      .then(res => {
        resolve(res?.data?.data || [])
      })
      .catch(err => reject(err));
  });

/**
 * Author: Suraj Kumar
 * Dated: 10 October 2024
 * Summary: Handles redirection to the next question flow.
 * Description:
 * - This function is used to handle the redirection to the next question flow in a chatbot conversation.
 * - It retrieves the target flow from API or Store based on the `nextQuestion` target.
 * - If the flow is found, it appends the flow and its questions to the `finalQuestions` array.
 * - The function returns the updated `finalQuestions` array.
 * - In case of an error, it logs the error message.
 */
const handleRedirectFlow = async (question: IQuestion) => {
  try {
    if (question.type !== 'redirect') {
      return question;
    }

    const state = STORE.getState();
    const redirectFlows = state.redirectFlows || [];

    const redirectedFlow = (redirectFlows || []).find((flow: IFlow) => flow._id === question.next.target);
    if (redirectedFlow) {
      STORE.dispatch(updateFlow([redirectedFlow]));
      return redirectedFlow;
    }

    const flow = await getRedirectFlow(question);
    STORE.dispatch(updateRedirectFlows([...redirectFlows, flow]));
    STORE.dispatch(updateFlow([flow]));

    return flow;
  } catch (error: any) {
    throw new Error('Something went wrong while fetching redirect flow.');
  }
}

export const getRedirectFlow = (question: any) =>
  new Promise((resolve, reject) => {

    const binary = encryptPayload({ flowId: question.next.target });

    axios({
      url: `${process.env.REACT_APP_API_URL}get-website-flow`,
      method: 'POST',
      data: { binary }
    })
      .then(res => {
        resolve(res?.data?.data || [])
      })
      .catch(err => reject(err));
  });
